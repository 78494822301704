// stores/useSearchStore.ts
import { ref, onMounted, nextTick } from 'vue';
import { defineStore } from 'pinia';
import { liteClient as algoliasearch } from 'algoliasearch/lite';
import axios from 'axios';

export const useSearchStore = defineStore('search', () => {
    const query = ref('');
    const selectedTopic = ref('');
    const selectedResourceType = ref('');
    const results = ref<any[]>([]);
    const autocompleteResults = ref<any[]>([]);
    const total = ref(0);
    const totalPages = ref(0);
    const page = ref(1);
    const showAutocomplete = ref(false);
    const searchTopics = ref<any[]>([]);
    const searchResourceTypes = ref<any[]>([]);
    const isLoading = ref(false);
    const isSearchPage = ref<boolean>(typeof window !== 'undefined' && window.location.pathname.includes('/search'));

    const RESULTS_PER_PAGE = 12;

    const appId = import.meta.env.VITE_ALGOLIA_APPLICATION_ID;
    const apiKey = import.meta.env.VITE_ALGOLIA_SEARCH_API_KEY;
    const searchClient = algoliasearch(appId, apiKey);
    const indexName = import.meta.env.VITE_ALGOLIA_INDEX_PREFIX + 'ALL_CONTENT';

    const updateURL = (params: Record<string, string | number>) => {
        if (!isSearchPage.value) return;

        const queryString = new URLSearchParams({
            q: String(params.q ?? ''),
            topic: String(params.topic ?? ''),
            resourceType: String(params.resourceType ?? ''),
            page: String(params.page ?? 1),
        });

        const newUrl = `${window.location.pathname}?${queryString.toString()}`;
        if (window.location.search !== `?${queryString.toString()}`) {
            window.history.replaceState({}, '', newUrl);
        }
    };

    const populateFromURL = () => {
        if (!isSearchPage.value) return;

        const params = new URLSearchParams(window.location.search);
        query.value = params.get('q') || '';
        selectedTopic.value = params.get('topic') || '';
        selectedResourceType.value = params.get('resourceType') || '';
        page.value = parseInt(params.get('page') || '1');
    };

    const fetchSearchResults = async () => {
        if (!query.value.trim() && !selectedTopic.value && !selectedResourceType.value) {
            results.value = [];
            total.value = 0;
            totalPages.value = 0;
            console.warn('⚠️ No search query or filters provided.');
            return;
        }

        isLoading.value = true;

        try {
            let facetFilters: string[][] = [];

            if (selectedTopic.value) {
                facetFilters.push([`relatedTopics:${selectedTopic.value}`]);
            }

            if (selectedResourceType.value) {
                facetFilters.push([`relatedResourceTypes:${selectedResourceType.value}`]);
            }

            const response = await searchClient.search([
                {
                    indexName,
                    query: query.value || '',
                    params: {
                        hitsPerPage: 1000,
                        facetFilters: facetFilters.length ? facetFilters : undefined,
                        getRankingInfo: true,
                    },
                },
            ]);

            const allResults = response.results[0]?.hits || [];

            // Optional: sort results by relevance or other ranking info
            allResults.sort((a, b) => (b._rankingInfo?.nbExactWords || 0) - (a._rankingInfo?.nbExactWords || 0));

            total.value = allResults.length;
            totalPages.value = Math.ceil(total.value / RESULTS_PER_PAGE);
            results.value = allResults.slice((page.value - 1) * RESULTS_PER_PAGE, page.value * RESULTS_PER_PAGE);

            updateURL({
                q: query.value,
                topic: selectedTopic.value,
                resourceType: selectedResourceType.value,
                page: page.value,
            });
        } catch (error) {
            console.error('🚨 Error fetching search results:', error);
        } finally {
            isLoading.value = false;
        }
    };


    let lastQuery = '';
    const fetchAutocompleteResults = async () => {
        if (!query.value || query.value.length < 3 || query.value === lastQuery) {
            showAutocomplete.value = false;
            autocompleteResults.value = [];
            return;
        }

        lastQuery = query.value;

        try {
            let facetFilters: string[][] = [];

            if (selectedTopic.value) {
                facetFilters.push([`relatedTopics:${selectedTopic.value}`]);
            }

            if (selectedResourceType.value) {
                facetFilters.push([`relatedResourceTypes:${selectedResourceType.value}`]);
            }

            const response = await searchClient.search([
                {
                    indexName,
                    query: query.value,
                    params: {
                        hitsPerPage: 10, // grab extra to allow sorting/slicing
                        getRankingInfo: true,
                        attributesToRetrieve: [
                            'title',
                            'url',
                            'teaser',
                            'autoDescription',
                            'copy',
                            'sectionHandle',
                            'relatedTopics',
                            'relatedResourceTypes',
                        ],
                        facetFilters: facetFilters.length ? facetFilters : undefined,
                    },
                },
            ]);

            const hits = response.results[0]?.hits || [];

            // Optional: sort by title relevance or other criteria
            hits.sort((a, b) => (b._rankingInfo?.nbExactWords || 0) - (a._rankingInfo?.nbExactWords || 0));

            autocompleteResults.value = hits.slice(0, 5);
            showAutocomplete.value = autocompleteResults.value.length > 0;

            await nextTick();
        } catch (error) {
            console.error('❌ Error fetching autocomplete results:', error);
            autocompleteResults.value = [];
            showAutocomplete.value = false;
        }
    };


    const fetchFacets = async () => {
        try {
            const response = await axios.get('/actions/readinguniverse/algolia/get-facets-from-craft');
            searchTopics.value = response.data.topicsFacets || [];
            searchResourceTypes.value = response.data.resourceFacets || [];
        } catch (error) {
            console.error('🚨 Error fetching facets:', error);
            searchTopics.value = [];
            searchResourceTypes.value = [];
        }
    };

    onMounted(() => {
        populateFromURL();
        fetchFacets();
    });

    return {
        query,
        selectedTopic,
        selectedResourceType,
        results,
        autocompleteResults,
        total,
        totalPages,
        page,
        isLoading,
        showAutocomplete,
        searchTopics,
        searchResourceTypes,
        fetchSearchResults,
        fetchAutocompleteResults,
        fetchFacets,
        updateURL,
        populateFromURL,
    };
});
